import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICON_BACK} from '../../../../assets/media/svg_icons/icon-back';
import { CurrencyUtil, EbFactoring, McGod } from '@miticon-ui/mc-core';
import { TranslateService } from '@ngx-translate/core';
import { ICON_STATUS_INACTIVE} from '../../../../assets/media/svg_icons/icon-status-inactive';
import { ICON_STATUS_ACTIVE } from '../../../../assets/media/svg_icons/icon-status-active';
import { ICON_STATUS_WAITING_FOR_APPROVAL } from '../../../../assets/media/svg_icons/icon-status-waiting-for-approval';

@Component({
  selector: 'lib-mc-my-factoring-view-contact',
  templateUrl: './mc-my-factoring-view-contact.component.html',
  styleUrl: './mc-my-factoring-view-contact.component.scss'
})
export class McMyFactoringViewContactComponent {

  iconBack = ICON_BACK;
  ebFactoringContract: any
  iconStatus: string;

  constructor(private router: Router,
              private tS: TranslateService) {

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.ebFactoringContract = navigation.extras.state['contractData'];
    }
    this.ebFactoringContract = history.state.contractData;
    this.getStatusIcon(this.ebFactoringContract.statusCd)
    if (!this.ebFactoringContract) this.goToPrevious();
  }

  private getStatusIcon(status: string) {
    const inactiveStatuses = [EbFactoring.STATUS_CD_REVOKED, EbFactoring.STATUS_CD_INACTIVE, EbFactoring.STATUS_CD_REJECTED, EbFactoring.STATUS_CD_REMOVED,];

    this.iconStatus = status === EbFactoring.STATUS_CD_ACTIVE
      ? ICON_STATUS_ACTIVE
      : inactiveStatuses.includes(status)
        ? ICON_STATUS_INACTIVE
        : ICON_STATUS_WAITING_FOR_APPROVAL;
  }

  isStatusPending(){
    return this.ebFactoringContract.statusCd === EbFactoring.STATUS_CD_PENDING_CONFIRMATION || EbFactoring.STATUS_CD_PENDING_ACCEPTANCE;
  }

  getFormattedStatus(status: string): string {
    if (!status) return '-';
    return status.replace(/_/g, ' ').split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  goToPrevious() {
    const backRoute = localStorage.getItem('parentEntityId') === 'undefined' ? 'out-factoring' : 'my-factoring'
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/${backRoute}/contracts`]);
  }

  format(value: number): string {
    return CurrencyUtil.format(value, this.ebFactoringContract.mcCurrency.code);
  }

  getMonthsAllowedPastCount(): string {
    const count = this.ebFactoringContract.monthsAllowedPastCount;
    return `${count} ${this.tS.instant('cc.common.date.months')}`;
  }

  getMonthsAllowedFutureCount(): string {
    const count = this.ebFactoringContract.monthsAllowedFutureCount;
    return `${count} ${this.tS.instant('cc.common.date.months')}`;
  }

  getHardLimitForCurrentEntityInLastDays(): string {
    return this.ebFactoringContract.hardLimitForCurrentEntity ? `${this.format(this.ebFactoringContract.hardLimitForCurrentEntity)} ${this.tS.instant('cc.contract.in-last')} ${this.ebFactoringContract.hardLimitForCurrentEntityDays} ${this.tS.instant('cc.factoring.day-s')}` : '-';
  }

  getHardCumulativeLimitInLastDays(): string {
    return (this.ebFactoringContract.hardCumulativeLimit >= 0) ?`${this.format(this.ebFactoringContract.hardCumulativeLimit)} ${this.tS.instant('cc.contract.in-last')} ${this.ebFactoringContract.hardCumulativeLimitDays} ${this.tS.instant('cc.factoring.day-s')}` : '-';
  }

  getSplit(){
    return this.ebFactoringContract.percentageForInitialPayoutNonFactoring ? this.ebFactoringContract.percentageForInitialPayoutNonFactoring + ' ' + '% ' + this.tS.instant('cc.contracts.to-be-paid-out') + ' ' + this.ebFactoringContract.noDaysForInitialPayoutNonFactoring + ' ' + this.tS.instant('cc.contracts.day-s-after-withdrawal').toLowerCase() : '-';
  }

  getRestToBePaid(): string {
    return this.ebFactoringContract.noDaysForTheRestToBePaidNonFactoring ? this.ebFactoringContract.noDaysForTheRestToBePaidNonFactoring + ' ' + this.tS.instant('cc.contracts.day-s-after-withdrawal') : '-';
  }

  getSendToBl(): string {
    return this.ebFactoringContract.sendClaimsToBlacklistFlg ? this.tS.instant('cc.common.edit.yes') : this.tS.instant('cc.common.edit.no')
  }

  getRemoveFromBl(): string{
    return this.ebFactoringContract.removeMemberWithoutOpenDebtFromBlFlg ? this.tS.instant('cc.common.edit.yes') : this.tS.instant('cc.common.edit.no')
  }

  isFactoringType(): boolean{
    return this.ebFactoringContract.factoringContractCd === EbFactoring.TYPE_CD_FACTORING;
  }

  getBlacklistTriggerSoftBounceCount() {
    return this.ebFactoringContract.blacklistTriggerSoftBounceCount ? `${this.ebFactoringContract.blacklistTriggerSoftBounceCount} ${this.tS.instant('cc.contract.soft-bounce-failed-withdrawals')}` : '-';
  }

  getBlacklistTriggerHardBounceCount() {
    return this.ebFactoringContract.blacklistTriggerHardBounceCount ? `${this.ebFactoringContract.blacklistTriggerHardBounceCount} ${this.tS.instant('cc.contract.hard-bounce-failed-withdrawals')}` : '-';
  }
}
